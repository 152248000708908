function Footer() {
    return (
        <footer>
            <p>ERA Česká republika<br />
                <a href="https://www.eracz.cz/" target="_blank">www.eracz.cz</a>
            </p>
        </footer>
    );
}

export default Footer;