
function ErrorPage() {
    return (
        <main className="error">
            NA TÉTO ADRESE NENÍ ŽÁDNÝ OBSAH.<br />
            POKRAČUJTE NA <a href="/">HLAVNÍ STRÁNKU</a>
        </main>
    )
}

export default ErrorPage;